import { ApiS3File } from 'api/types/ApiS3File';

export enum CmsApiLinkType {
  CMS_PAGE = 'CMS_PAGE',
  URL = 'URL',
  S3_FILE = 'S3_FILE',
  SUBSCRIBE = 'SUBSCRIBE',
}

type CmsApiLinkBase = {
  text: string;
  type: CmsApiLinkType;
  data: object;
};

export type CmsApiUrlLink = CmsApiLinkBase & {
  type: CmsApiLinkType.URL;
  data: {
    url: string;
  };
};

export type CmsApiCmsPageLink = CmsApiLinkBase & {
  type: CmsApiLinkType.CMS_PAGE;
  data: {
    url?: string;
    pageTitle?: string;
  };
};

export type CmsApiS3FileLink = CmsApiLinkBase & {
  type: CmsApiLinkType.S3_FILE;
  data: {
    s3FileId: string;
    s3File: ApiS3File | null;
    permission: 'PUBLIC' | 'USER';
  };
};

export type CmsApiSubscribeLink = CmsApiLinkBase & {
  type: CmsApiLinkType.SUBSCRIBE;
  data: {
    iterableMessageTypeId: number | null;
    iterableListId: number | null;
    redirectUrl?: string;
  };
};

export type CmsApiLink =
  | CmsApiUrlLink
  | CmsApiCmsPageLink
  | CmsApiS3FileLink
  | CmsApiSubscribeLink;
