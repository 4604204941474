import invariant from 'invariant';

import { CmsApiLink, CmsApiLinkType } from 'modules/cms/api/types/CmsApiLink';

import { CmsDownloadButtonLoadable } from './CmsDownloadButtonLoadable';
import { CmsLinkButtonLoadable } from './CmsLinkButtonLoadable';
import { CmsSubscribeButtonLoadable } from './CmsSubscribeButtonLoadable';
import { CmsButtonRender } from './types/CmsButtonRender';

type Props = {
  buttonData: CmsApiLink;
  eventProperties: Record<string, unknown>;
  blockId?: string;
  sectionIndex?: number;
  render: CmsButtonRender;
};

/*
 * A generic button that handles all types of CMS "call to action" buttons
 */
export function CmsButton({
  eventProperties,
  buttonData,
  blockId,
  sectionIndex,
  render,
}: Props) {
  if (buttonData.type === CmsApiLinkType.S3_FILE) {
    return (
      <CmsDownloadButtonLoadable
        buttonData={buttonData}
        eventProperties={{ cta_type: 'button', ...eventProperties }}
        render={render}
      />
    );
  }

  if (buttonData.type === CmsApiLinkType.SUBSCRIBE) {
    invariant(blockId !== undefined, 'Missing block id for CmsButton');
    invariant(
      sectionIndex !== undefined,
      'Missing section index for CmsButton',
    );
    return (
      <CmsSubscribeButtonLoadable
        buttonData={buttonData}
        blockId={blockId}
        sectionIndex={sectionIndex}
        eventProperties={{ cta_type: 'button', ...eventProperties }}
        render={render}
      />
    );
  }

  if (
    buttonData.type === CmsApiLinkType.URL ||
    buttonData.type === CmsApiLinkType.CMS_PAGE
  ) {
    return (
      <CmsLinkButtonLoadable
        buttonData={buttonData}
        eventProperties={{ cta_type: 'button', ...eventProperties }}
        render={render}
      />
    );
  }

  return null;
}
